.container{
    width: 100%;
    height:40vh;
    background: url(../../assets/verified-certified-affirm-authorised-approve-concept\ 1.png);
    background-repeat:no-repeat;
    background-size:cover; 
    background-position:center;
}

.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(22, 22, 22, 0.63);
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center;
    padding: 20px;
}
.head{
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
}
.texts{
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
}
@media(min-width: 768px){
    .container{
        height: 75vh;
        margin-top: 0;
    }
    .overlay{
        padding: 100px;
    }
    .head{
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .texts{
        font-family: 'Work Sans';
        font-style: normal;
        font-size: 20px;
        font-weight: 500;
    }
}

@media(min-width: 1280px){
    .container{
        height: 100vh;
        margin-top: 10vh;
    }
    
    .overlay{
        justify-content: flex-start;
        padding: 0;
    }
    .head{
        font-family: 'Work Sans';
        font-style: normal;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .texts{
        font-family: 'Work Sans';
        font-style: normal;
        font-size: 24px;
        font-weight: 500;
    }
}
@media screen and (min-width:1400px){
    .container{
        height: 100vh;
    }
    .gen{
        height: 35%;
    }
    
    .head{
        font-size: 60px;
        margin-bottom: 30px;
        line-height: 60px;
    }
    .texts{
        font-size: 30px;
        margin-top: 3%;
    }
}
@media screen and (min-width:1800px){
    .container{
        margin-top: 0;
    }
    .gen{
        height: 50%;
    }
    .textHead{
        font-size: calc(36px + 0.8vw) ;
        line-height: calc(42.19px + 2vh) ;
        font-weight: 800;
    }
    
    .textBody{
        font-size: calc(16px + 0.5vw) ;
        line-height: calc(22px + 1.5vh);
    }
}

@media screen and (orientation:landscape) and (max-width: 900px){
    .container{
        height: 500px;
        margin-top: 0;
    }
   
    .gen{
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 75%;
        align-self: center;
    }
    .textsCont{
        height: 60%;
    }
    .textHead{
        font-size: 24px;
        line-height: 35px;
    }
    .textBody{
        font-size: 14px;
        line-height: 25px;
    }
    .btn{
        height: 40px;
        width: 100px;
    }
    
}
@media screen and (orientation:landscape) and (max-width: 1100px){
    .container{
        height: 700px;
        margin-top: 0;
    }
    .gen{
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 75%;
        align-self: center;
    }

    
}