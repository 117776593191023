.peopleDiv:hover {
    background: rgba(248, 80, 26, 0.71);
}/* people that use verifier */
.peopleDiv {
    background: rgba(248, 80, 26, 1);
}

.peopleDivbg {
    background: url(../assets/privacy-security-data-protection-shield-graphic-concept\ 1.png);
    background-repeat: no-repeat;
    background-size: cover;
}



/* each steps image */
.step{
    background: rgba(252, 192, 139, 0.28);
}

.blogFirstDiv{
    background: url(../assets/about\ us\ \(1\).png) ;
}


/* breakpoint */
@media screen and (min-width:1400px){
    .verifierText {
        line-height: 40px;
    }

    .aboutVerifierDiv{
        height: 100vh;
    }

    .aboutVerifierSmallBox {
        bottom: 5%;
        right:7%;
 }  

 .blog{
    height: 100vh;
 }

}

@media only screen and (min-width:1800px){
    .text36{
        font-size: calc(36px + 0.6vw);
        line-height: calc(42px + 1.5vh) ;
    }
    .text32{
        font-size: calc(32px + 0.8vw);
        line-height: calc(38px + 1.5vh) ;
    }
    .text20{
        font-size: calc(20px + 0.8vw);
        line-height: calc(23px + 1.5vh) ;
    }
    .text24{
        font-size: calc(24px + 0.8vw);
        line-height: calc(30px + 1.5vh) ;
    }
    .text15{
        font-size: calc(15px + 0.3vw);
        line-height: calc(17px + 1vh) ;
    }
    .text16{
        font-size: calc(16px + 0.3vw);
        line-height: calc(29px + 1vh) ;
    }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
    .aboutVerifierDiv{
        height: 1300px; 
     }
    .aboutVerifierDiv>div{
        height: 75%;  
        margin: 0;
     }
    .aboutVerifierImgDiv{
        height: 600px;
        width: 600px;
    }
     .aboutVerifierSmallBox{
        height: 100px;
        width: 100px;
        bottom: -5%;
     }
     .abtDiv{
        margin-top: 0;
        height: 35%;
    }
   
    .conBtn{
        width: 150px;
    }
    .blog{
        height: auto;
    }
    .blogFirstDiv{
       height: 10%; 
    }
    .LargeNavbar{
        display: flex;
        width: 100vw;
        padding: 0;
        margin-right: 0;
        top: 0;
        left: 0;
        right: 0;
    }
    .logoCont{
        width: 20%;
    }
    .logo{
        width: 100%;
    }
    .LargeNavbarItems{
        width: 45%;
    }
    .blog{
        height: 500px;
    }
    .blogFirstDiv{
        margin-bottom: 150px;
    }
    .blogs{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .blogCard{
        width:45vw;
        margin: 15px;
    }
    .blogImg{
       width:100%;   
    }
    .how{
        width: 60%;
    }
    .stepsCont{
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
      
    }
}

@media screen and (orientation: landscape) and (min-width: 1000px) and (max-width: 1100px) {
    .aboutVerifierDiv{
        height: 1650px;
       
     }
     .blog{
        height: 1300px;
    }
    
}